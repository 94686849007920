/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  useDispatch, connect, useSelector
} from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import FeatherIcon from "feather-icons-react";
// import { Link } from "react-router-dom";

// i18n
import { withTranslation } from "react-i18next";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
import { deleteTradingAccount, fetchTradingAccounts } from "store/actions";
import CreateTradingAccount from "components/Common/CreateTradingAccount";
import { useParams } from "react-router-dom";
import formatDate from "helpers/formatDate";
import DeleteModal from "components/Common/DeleteModal";
import ChangeAccess from "../Profile/QuickActions/tradingAccounts/ChangeAccess";
import LinkMT5 from "../Profile/QuickActions/tradingAccounts/LinkMT5";
import ChangePassword from "../Profile/QuickActions/tradingAccounts/ChangePassword";
import ChangeType from "../Profile/QuickActions/tradingAccounts/ChangeType";
import ChangeLeverage from "../Profile/QuickActions/tradingAccounts/ChangeLeverage";
import useModal from "hooks/useModal";
import Positions from "./Positions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LinkAccount from "../Profile/QuickActions/tradingAccounts/LinkAccount";
import UnLinkAccount from "../Profile/QuickActions/tradingAccounts/UnLinkAccount";
import ViewAgreementModal from "./ViewAgreementModal";

function Accounts(props) {
  const [sizePerPage, setSizePerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const { clientId } = useParams();
  const [platform, setPlatform] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);

  const [showAccessModal, setShowAccessModal] = useModal();
  const [showLinkModal, setShowLinkModal] = useModal();
  const [showPassModal, setShowPassModal] = useModal();
  const [showTypeModal, setShowTypeModal] = useModal();
  const [showLeverageModal, setShowLeverageModal] = useModal();
  const [showUnlinkModal, toggleUnlinkModal] = useModal();
  const [showLinkIbModal, toggleLinkIbModal] = useModal();
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = userData?.roleId?.permissions;
  const {tradingAccount} = permissions;

  const columns = [
    {
      dataField: "login",
      text: props.t("Login"),
      formatter: (val) => (
        <a
          className="fw-bold"
          style={{ cursor: "pointer" }}
          onClick={() => setAccountId(val._id)}
        >
          {val?.login || ""}
        </a>
      ),
    },
    {
      dataField: "accountTypeId",
      text: props.t("Account Type"),
      formatter: (val) => val?.accountTypeId?.title ?? val.title ?? "",
    },
    {
      dataField: "accountTypeId",
      text: props.t("Account Group"),
      formatter: (val) => {
        const groupPath = val?.accountTypeId?.groupPath;
        const currencyGroupPath = val?.accountTypeId?.currencies?.[0]?.groupPath;
        
        if (groupPath === undefined && currencyGroupPath === undefined) {
          return "-";
        }
        
        return groupPath || currencyGroupPath;
      },
    },
    {
      dataField: "accountTypeId",
      text: props.t("Platform"),
      formatter: (val) => val?.accountTypeId?.platform ?? val.platform ?? "",
    },
    {
      dataField: "accountTypeId",
      text: props.t("Type"),
      formatter: (val) => val?.accountTypeId?.type ?? val.type ?? "",
    },
    {
      dataField: "currency",
      text: props.t("Currency"),
    },
    {
      dataField: "MarginLeverage",
      text: props.t("Leverage"),
      formatter:(val)=> val.platform === "CTRADER" ? "1/" + val?.leverageInCents : "1/" + val.MarginLeverage
    },
    {
      dataField: "Balance",
      text: props.t("Balance"),
      formatter: (val) => val.platform === "CTRADER" ? val.balance / 100 :  val?.Balance
    },
    {
      dataField: "Credit",
      text: props.t("Credit"),
      formatter: (val) => val.platform === "CTRADER" ? val?.bonus  : val?.Credit
    },
    {
      dataField: "Equity",
      text: props.t("Equity"),
      formatter: (val) =>  val.platform === "CTRADER" ? val?.equity / 100 : val?.Equity 
    },
    {
      dataField: "Margin",
      text: props.t("Margin"),
      formatter: (val) => val.platform === "CTRADER" ? val?.usedMargin / 100 : val?.Margin
    },
    {
      dataField: "MarginLevel",
      text: props.t("Margin Level"),
      formatter: (val) => console.log(val),
    },
    {
      dataField: "MarginFree",
      text: props.t("Margin Free"),
      formatter: (val) => val.platform === "CTRADER" ? val?.freeMargin / 100 : val?.MarginFree
    },
    {
      dataField: "createdAt",
      text: props.t("Date Created"),
      formatter: (val) => formatDate(val.createdAt),
    },
    {
      dataField: "agreement",
      text: props.t("Agreement"),
      formatter: (val) => val?.ibLink ? <div>
        <FeatherIcon icon="book" className="text-success cursor-pointer" onClick={() => {
          setSelectedAgreement(val?.ibLink?.agreementId);
        }} />
      </div> : "-",
    },
    {
      dataField: "ib",
      text: props.t("IB"),
      formatter: (val) => val?.ibLink ? <Link className="fw-bold" to={`/clients/${val?.ibLink?.ibAccountId?.customerId?._id}/profile`}>{`${val?.ibLink?.ibAccountId?.customerId?.firstName} ${val?.ibLink?.ibAccountId?.customerId?.lastName}`}</Link> : val?.ib ?? "",
    },
    {
      dataField: "dropdown",
      text: props.t("Actions"),
      formatter: (val) => (
        <UncontrolledDropdown>
          <DropdownToggle tag="i" style={{ cursor: "pointer" }}>
            <i
              className="mdi mdi-dots-horizontal font-size-18"
              onClick={() => {
                setSelectedAccount(val);
              }}
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {
              val?.type === "LIVE" && <DropdownItem onClick={()=>{
                if (val?.ibLink) {
                  toggleUnlinkModal();
                } else {
                  toggleLinkIbModal();
                }
                setSelectedAccount(val);
              }} href="#">
                {val?.ibLink ? props.t("Unlink from IB") : props.t("Link Account to IB")}
              </DropdownItem>
            }
            <DropdownItem onClick={() => {
              setPlatform(val.platform);
              setShowTypeModal();
            }} href="#">
              {props.t("Change Type")}
            </DropdownItem>
            {/* <DropdownItem onClick={setShowPassModal} href="#">
              {props.t("Reset Password")}
            </DropdownItem> */}
            {/* <DropdownItem onClick={setShowAccessModal} href="#">
              {props.t("Change Access")}
            </DropdownItem> */}
            <DropdownItem onClick={() => {
              setPlatform(val.platform);
              setShowLeverageModal();
            } } href="#">
              {props.t("Change Leverage")}
            </DropdownItem>
            <DropdownItem onClick={() => {
              setShowDeleteModal(true);
              setAccountId(val._id);
            } } href="#">
              {props.t("Delete Account")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];
  const dispatch = useDispatch();
  const fetchData = async (page) => {
    dispatch(
      fetchTradingAccounts({
        page: page || 1,
        limit: sizePerPage,
        customerId: clientId,
      })
    );
  };

  const deleteAccount = () => {
    dispatch(deleteTradingAccount({
      id: accountId,
    }));
    setShowDeleteModal(false);
    setIsDeleted(true);
  };


  useEffect(() => {
    fetchData(1);
  }, [sizePerPage, clientId, isDeleted]);

  useEffect(() => {
    if (props.deletingClearCounter > 0 && showDeleteModal) {
      setShowDeleteModal(false);
    }
  }, [props.deletingClearCounter]);

  return (
    <React.Fragment>
      <div className="">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              {
              tradingAccount.get ?
                <Card>
                <CardHeader className="d-flex justify-content-between align-items-center gap-2">
                  <CardTitle className="color-primary">
                    {props.t("Trading Accounts")} ({props.totalDocs})
                    <FeatherIcon
                      icon="refresh-cw"
                      className="icon-lg ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        fetchData(props.currentPage);
                      }}
                    />
                  </CardTitle>
                  {tradingAccount.create &&
                  <Row className="gap-2">
                    <div className="d-flex">
                      <div className="mx-3">
                        <LinkMT5
                          show={showLinkModal}
                          toggle={setShowLinkModal}
                          customerId={clientId}
                          platform="MT5"
                        />
                      </div>
                      <div className="mx-3">
                       <CreateTradingAccount
                         show={showModal}
                         onClose={() => {
                           setShowModal(false);
                         }}
                         hidenAddButton={false}
                         customerId={clientId}
                       />
                      </div>

                    </div>
                  </Row>}
                  <DeleteModal
                    loading={props.deleteLoading}
                    onDeleteClick={deleteAccount}
                    show={showDeleteModal }
                    onCloseClick={()=>{setShowDeleteModal(false)}}
                  />
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                      style={{
                        overflow: "visible",
                        overflowX: "auto",
                      }}
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover"
                      >
                        <Thead className="text-center table-light">
                          <Tr>
                            {tradingAccount.actions ?
                             columns.map((column, index) =>
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">{column.text}</span>
                              </Th>
                            ) :
                            columns.filter((column) => column.text !== "Actions").map((column, index) =>
                              <Th data-priority={index} key={index}>
                                <span className="color-primary">{column.text}</span>
                              </Th>
                            )
                          }
                          </Tr>
                        </Thead>
                        <Tbody>
                          {props.fetchAllTradingAccountsLoading && <TableLoader colSpan={4} />}                            
                          {!props.fetchAllTradingAccountsLoading &&
                            props.accounts.length === 0 && (
                              <>
                                <Tr>
                                  <Td
                                    colSpan={"100%"}
                                    className="fw-bolder text-center"
                                    st="true"
                                  >
                                    <h3 className="fw-bolder text-center">
                                      {props.t("No records")}
                                    </h3>
                                  </Td>
                                </Tr>
                              </>
                            )}
                          {!props.fetchAllTradingAccountsLoading &&
                            props.accounts.length !== 0 &&
                            props.accounts.map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {tradingAccount.actions ? columns.map((column, index) => (
                                  <Td
                                    key={`${rowIndex}-${index}`}
                                    className="text-center"
                                  >
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </Td>
                                ))
                                :
                                columns.filter((column) => column.text !== "Actions").map((column, index) => (
                                  <Td
                                    key={`${rowIndex}-${index}`}
                                    className="text-center"
                                  >
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </Td>
                                ))
                              }
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                      <CustomPagination
                        {...props.pagination}
                        docs={props.accounts}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={fetchData}
                      />
                    </div>
                  </div>
                </CardBody>
                <hr className="my-2" />
                <Positions accountId={accountId} />
                </Card>
                : <div className="text-center">
                  <h2>{props.t("You Do Not Have The Required Permission")}</h2>
                </div>
              }
            </Col>
          </Row>
        </div>
      </div>
      <div className="d-none">
        <ChangePassword
          show={showPassModal}
          toggle={setShowPassModal}
          customerId={clientId}
          selectedAcc={selectedAccount}
        />
        <ChangeType
          show={showTypeModal}
          toggle={setShowTypeModal}
          customerId={clientId}
          selectedAcc={selectedAccount}
          platform={platform}
        />
        <ChangeAccess
          show={showAccessModal}
          toggle={setShowAccessModal}
          customerId={clientId}
          selectedAccount={selectedAccount}
        />
        <ChangeLeverage
          show={showLeverageModal}
          toggle={setShowLeverageModal}
          customerId={clientId}
          selectedAccount={selectedAccount}
          platform={platform}
        />
        <LinkAccount
          show={showLinkIbModal}
          toggle={toggleLinkIbModal}
          customerId={clientId}
          selectedAccount={selectedAccount}
          fetchData={fetchData}
        />
        <UnLinkAccount
          show={showUnlinkModal}
          toggle={toggleUnlinkModal}
          customerId={clientId}
          selectedAccount={selectedAccount}
          fetchData={fetchData}
        />
        {selectedAgreement && <ViewAgreementModal
          show={selectedAgreement}
          toggle={() => setSelectedAgreement(null)}
          agreement={selectedAgreement}
        />}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  clientDetails: state.clientReducer.clientDetails || {},
  accounts:
    (state.tradingAccountReducer.accounts &&
      state.tradingAccountReducer.accounts.docs) ||
    [],
  totalDocs:
    (state.tradingAccountReducer.accounts &&
      state.tradingAccountReducer.accounts.totalDocs) ||
    0,
  currentPage:
    (state.tradingAccountReducer.accounts &&
      state.tradingAccountReducer.accounts.page) ||
    1,
  pagination: state.tradingAccountReducer.accounts || {},
  loading: state.tradingAccountReducer.loading,
  fetchAllTradingAccountsLoading:
    state.tradingAccountReducer.fetchAllTradingAccountsLoading,
});

export default connect(mapStateToProps, null)(withTranslation()(Accounts));
