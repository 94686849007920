import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchTradingAccounts } from "store/actions";
import { CardBody } from "reactstrap";
import useModal from "hooks/useModal";
import CreateMT5 from "./CreateMT5";
import ChangeLeverage from "./ChangeLeverage";
import ChangePassword from "./ChangePassword";
import LinkMT5 from "./LinkMT5";
import ChangeType from "./ChangeType";
import ChangeAccess from "./ChangeAccess";

const TradingAccountsQuickActions = ({ clientId, tradingAccountPermissions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTradingAccounts({
      customerId: clientId,
    }));
  }, []);

  const [showAddModal, setShowAddModal] = useModal();
  const [showAddMT5Modal, setShowAddMT5Modal] = useModal();
  const [showAccessModal, setShowAccessModal] = useModal();
  const [showLinkMT5Modal, setShowLinkMT5Modal] = useModal();
  const [showLinkModal, setShowLinkModal] = useModal();
  const [showPassModal, setShowPassModal] = useModal();
  const [showTypeMT5Modal, setShowTypeMT5Modal] = useModal();
  const [showTypeModal, setShowTypeModal] = useModal();
  const [showLeverageMT5Modal, setShowLeverageMT5Modal] = useModal();
  const [showLeverageModal, setShowLeverageModal] = useModal();
  return (
    <CardBody className="quick-actions-card">
      <p className="quick-actions-heading">{t("MT5")}</p>
      <div className="btn-container">
        {tradingAccountPermissions.create &&
          <CreateMT5
            show={showAddMT5Modal}
            toggle={setShowAddMT5Modal}
            customerId={clientId}
            platform="MT5"
          />
        }
       
        {tradingAccountPermissions.update && 
        <>
          <LinkMT5
            show={showLinkModal}
            toggle={setShowLinkModal}
            customerId={clientId}
            platform="MT5"
          />
          
          <ChangeType
            show={showTypeMT5Modal}
            toggle={setShowTypeMT5Modal}
            platform={"MT5"}
          />
          <ChangeLeverage
            show={showLeverageMT5Modal}
            toggle={setShowLeverageMT5Modal}
            platform="MT5"
          />
          <ChangeAccess
            show={showAccessModal}
            toggle={setShowAccessModal}
          />
          <ChangePassword
            show={showPassModal}
            toggle={setShowPassModal}
          />
        </>
        
        }
      </div>

      <p className="quick-actions-heading mt-4">{t("CTrader")}</p>
      <div className="btn-container">
        {tradingAccountPermissions.create &&
          <CreateMT5
            show={showAddModal}
            toggle={setShowAddModal}
            customerId={clientId}
            platform="CTRADER"
          />
        }
        {/* <ChangeAccess
          show={showAccessModal}
          toggle={setShowAccessModal}
        /> */}
        {/* <ChangePassword
          show={showPassModal}
          toggle={setShowPassModal}
        /> */}
        {tradingAccountPermissions.update && 
        <>
          <LinkMT5
            show={showLinkMT5Modal}
            toggle={setShowLinkMT5Modal}
            customerId={clientId}
            platform="CTRADER"
          />
          
          <ChangeType
            show={showTypeModal}
            toggle={setShowTypeModal}
            platform={"CTRADER"}
          />
          <ChangeLeverage
            show={showLeverageModal}
            toggle={setShowLeverageModal}
            platform={"CTRADER"}
          />
        </>

        }
      </div>
    </CardBody>
  );
};

export default TradingAccountsQuickActions;