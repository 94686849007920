import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";

// i18n 
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { approveKYCStart } from "store/documents/actions";

function ApproveKYC(props) {
  const isKycApproved = props?.clientDetails?.stages?.kycApproved;
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const { t } = props;


  return (
    <React.Fragment>
      <div className="">
        <div className="container-fluid">
          <Card>
            <CardHeader className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="color-primary">{t("Approve Kyc")}</CardTitle>
                <Button className="w-md" color="primary" disabled={isKycApproved} onClick={() => dispatch(approveKYCStart({ customerId: clientId }))}>{t("Approve")}</Button>
              </div>
            </CardHeader>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.clientReducer.loading,
  clientDetails: state.clientReducer.clientDetails,

});

export default connect(mapStateToProps, null)(withTranslation()(ApproveKYC));