import { useEffect, useState } from "react";
import React, { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  AvForm, AvField, AvRadioGroup, AvRadio
} from "availity-reactstrap-validation";
import {
  Modal, ModalHeader, ModalBody, Button, Row, Col, Label, Input,
} from "reactstrap";
import Loader from "components/Common/Loader";
import validatePositiveInputs from "helpers/validatePositiveInputs";
import { ACCOUNT_TYPES } from "common/data/trading-account";
import { linkTradingAccount } from "store/tradingAccounts/actions";

const LinkMT5 = ({ show, toggle, customerId, platform }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { accountTypes, creating, createCounter } = useSelector((state) => state.tradingAccountReducer);


  const [type, setType] = useState(null);
  const [filteredAccountTypes, setFilteredAccountTypes] = useState([]);
  const [accountCurrency, setSAccountCurrency] = useState([]);
  const [accountLeverages, setAccountLeverages] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [platformAccountTypes, setPlatformAccountTypes] = useState([]);

  const platforms = accountTypes?.length > 0 && [
    ...new Set(accountTypes?.map((item) => item.platform)),
  ];
  const typeValues = [...new Set(platformAccountTypes?.map(item => item.type))];
  useEffect(() => {
    setPlatformAccountTypes(
      accountTypes?.filter((account) => account.platform === selectedPlatform)
    );
  }, [selectedPlatform, accountTypes]);
  // filter accounts based on account's type (live/demo)

  // filter accounts based on account's type (live/demo)
  useEffect(() => {
    setFilteredAccountTypes(accountTypes?.filter((at) => at.type === type?.toUpperCase() && at.platform === selectedPlatform));
  }, [accountTypes, type, selectedPlatform]);

  useEffect(()=>{
    show && toggle();
  }, [createCounter]);

  return (
    <>
      <Button color="primary"   onClick={toggle}><i className="bx bx-plus"></i> {t("Link Account")}</Button>


      <Modal centered isOpen={show} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {t("Link Trading Account")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={(e, v) => {
              v.type = type;
              v.customerId = customerId;
              dispatch(linkTradingAccount(v));
            }}
          >
            <AvField type="text" name="login"
              label={t("Account Login")}
              className="mt-1 mb-2"
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Account login is required"),
                },
              }}
              onKeyPress={(e) => {
                validatePositiveInputs(e);
              }}
            />
            {platforms && (
              <Row className="border rounded-3 p-3">
                <h5>{t("Platforms")}</h5>
                {platforms.map((platform) => (
                  <Col key={platform} md="6" className="gy-3">
                    <div
                      className="custom-radio"
                      onClick={() => {
                        setSelectedPlatform(platform);
                        document.getElementById(platform).click();
                      }}
                      style={{
                        border: 0,
                      }}
                    >
                      <Input
                        className="mt-0 me-2"
                        id={platform}
                        name="platform"
                        type="radio"
                        value={platform}
                      ></Input>
                      <Label check for={platform}>
                        {t(platform)}
                      </Label>
                    </div>
                  </Col>
                ))}
              </Row>
            )}

            {selectedPlatform && (
              <Row className="border rounded-3 p-3">
                <h5>{t("Type")}</h5>
                { typeValues.length > 0 && typeValues.map((type) => 
                  <Col md="6" key={type} className="gy-3">
                    <div
                      className="custom-radio"
                      onClick={() => {
                        setType(type);
                        document.getElementById(type).click();
                      }}
                    >
                      <Input
                        className="mt-0 me-2"
                        id={type}
                        name="type"
                        type="radio"
                        value={type}
                      ></Input>
                      <Label check for={type}>
                        {t(type)}
                      </Label>
                    </div>
                  </Col>
                )}
              </Row>
            )}

            {type && (
              <AvRadioGroup
                name="accountTypeId"
                required
                errorMessage={t("Select Account Type")}
              >
                <Row className="border rounded-3 p-3 mt-3">
                  <h5>{t("Account Type")}</h5>
                  {filteredAccountTypes.length > 0 &&
                    filteredAccountTypes.map((type) => (
                      <Col key={type._id} md="6" className="gy-3">
                        <div
                          className="custom-radio"
                          onClick={() => {
                            setSAccountCurrency(type.currencies);
                            if (type?.leverages) {
                              setAccountLeverages(type.leverages);
                            } 
                            document
                              .getElementById(`radio-accountTypeId-${type._id}`)
                              .click();
                          }}
                        >
                          <AvRadio
                            label={t(type.title)}
                            value={type._id}
                          />
                        </div>
                      </Col>
                    ))}
                </Row>
              </AvRadioGroup>
            )}
            {accountCurrency.length > 0 && (
              <AvRadioGroup
                name="currency"
                required
                errorMessage={t("Select Currency")}
              >
                <Row className="border rounded-3 p-3">
                  <h5 className="mb-3">{t("Account Currency")}</h5>
                  {accountCurrency.map((currency) => (
                    <Col key={currency.currency} md="6" className="gy-3">
                      <div
                        className="custom-radio"
                        onClick={() => {
                          document
                            .getElementById(
                              `radio-currency-${currency.currency}`
                            )
                            .click();
                        }}
                      >
                        <AvRadio
                          label={t(currency.currency)}
                          value={currency.currency}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </AvRadioGroup>
            )}
            <div className="text-center mt-3 mb-1">
              {
                creating
                  ? <Loader />
                  : <Button type="submit" color="primary">{t("Link")}</Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LinkMT5;