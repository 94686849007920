import { useSelector } from "react-redux";
import {
  Card, CardBody, CardHeader, CardTitle
} from "reactstrap";
import GeneratePDF from "./generatePDF";
import PreviewPDF from "./PreviewPDF";
const { useTranslation } = require("react-i18next");

export const Applications = () => {
  const { t } = useTranslation();
  const { fx, isCorporate = false } = useSelector((state) => state?.clientReducer?.clientDetails ?? {
    fx: {
      isIb: false,
      isClient: false
    },
    isCorporate: false
  });
  const { isIb, isClient } = fx ?? {
    isIb: false,
    isClient: false
  };
  const aplicationTitles = [];
  if (isCorporate && isClient)
    aplicationTitles.push({
      title: "Corporate Application",
      isIb: false
    });
  else if (!isCorporate && isClient)
    aplicationTitles.push({
      title: "Individual Application",
      isIb: false
    });
  if (isCorporate && isIb)
    aplicationTitles.push({
      title: "Corporate IB Application",
      isIb: true
    });
  else if (!isCorporate && isIb)
    aplicationTitles.push({
      title: "Broker Application",
      isIb: true
    });

  if (!isIb && !isClient) return null;
  return (
    <div>
      <CardHeader className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between align-items-center quick-actions-card">
          <p className="quick-actions-heading">{t("Applications")}</p>
        </div>
      </CardHeader>
      <CardBody>
        {
          aplicationTitles.map((app, index) =>
            <div key={index} className="d-flex border-bottom justify-content-between align-items-center">
              <span className="me-auto">{t(app.title)}</span>
              <div className="mb-2">
                <PreviewPDF heading={app.title} isIb={app.isIb} t={t} />
              </div>
              <div className="mb-2">
                <GeneratePDF heading={app.title} isIb={app.isIb} t={t} />
              </div>
            </div>
          )
        }
      </CardBody>
    </div>

  );
};
