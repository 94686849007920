import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";


function TransactionModal({ show, toggle, onSubmit, showAmount, bonus, setBonus, title, data, submitButton, ...props }) {
  const bonusPercent = 0.3; // 30%
  const amount = data.amount.$numberDecimal ?? data.amount;
  const [note, setNote] = useState("");

  useEffect(() => {
    if (showAmount === true){
      if (amount) setBonus(amount * bonusPercent);
    }
  }, [showAmount]);

  return (<>
    <Modal isOpen={show} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {props.t(title)}
      </ModalHeader>
      <ModalBody>
        <AvForm
          className='p-4'
          onValidSubmit={(e, v) => {
            data.amount = parseFloat(v.amount);
            data.note = v.note || note;
            onSubmit(data);
          }}
        >
          {showAmount && <AvField
            name="amount"
            label={props.t("Amount")}
            placeholder={props.t("Enter Amount")}
            type="number"
            errorMessage={props.t("Enter Valid Amount")}
            disabled
            validate={{
              required: { value: true }
            }}
            defaultValue={data.amount.$numberDecimal ?? data.amount}
          />}
          <AvField
            name="note"
            label={props.t("Note")}
            placeholder={props.t("Enter Note")}
            type="text"
            onChange={(e) => setNote(e.target.value)}
            errorMessage={props.t("Enter Valid Note")}
            validate={{
              required: { value: true }
            }}
            defaultValue={data.note}
          />
          {/* {showAmount && <AvField
            name="bonus"
            label={props.t("BBonus(30%)")}
            placeholder={props.t("Enter Bonus")}
            type="number"
            validate={{
              required: {
                value: true,
                errorMessage: props.t("Enter Valid Bonus")
              },
              min: { value: 0 },
              max: {
                value: amount,
                errorMessage: props.t("Bonus should be less than the amount")
              }
            }}
            defaultValue={amount ? amount * bonusPercent : bonus}
            onChange={(e) => setBonus(e.target.value)}
          />} */}
          <div className='text-center'>
            <Button type="submit" color="primary" className="mx-2">
              {submitButton}
            </Button>
            <Button color="primary" className="mx-2" onClick={toggle}>
              Cancel
            </Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  </>);
}

export default TransactionModal;