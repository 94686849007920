import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { 
  Container, 
  Card, 
  Button
} from "react-bootstrap";
import {
  Table, Thead, Tbody, Tr, Th
} from "react-super-responsive-table";
import { AvForm } from "availity-reactstrap-validation";

import { fetchReferrals } from "store/client/actions";
import { fetchAgreements } from "store/actions";
import CustomSelect from "components/Common/CustomSelect";
import Referrals from "./Referrals";

const PLATFORMS = [
  "MT5", "CTRADER"
];

const Referral = ({ t }) => {
  const dispatch = useDispatch();
  const [refs, setRefs] = useState([]);
  const { _id, referrals = [] } = useSelector((state) => state.clientReducer?.clientDetails);
  const { agreements } = useSelector((state) => state.ibAgreements);
  const [platform, setPlatform] = useState("MT5");
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  useEffect(() => {
    if (_id && platform) {
      _id && dispatch(fetchReferrals({
        platform,
        clientId: _id
      }));
      dispatch(fetchAgreements({ customerId: _id }));
    }
  }, [_id, platform]);

  useEffect(() => {
    console.log(referrals);
    setRefs(referrals);
  }, [referrals]);

  return (
    <Container fluid className="gx-4">
      <AvForm className="d-flex">
        <div className="row m-2">
          <label className="color-primary">{t("Select Platform")}</label>
          <CustomSelect
            className="w-full"
            name="platform"
            onChange={(e) => { setPlatform(e.value) }}
            value={
              platform === null ? "" : undefined
            }
            options={PLATFORMS?.map((agreement) => {
              return ({
                label: agreement,
                value: agreement,
              });
            })}
          />
        </div>
        <div className="row m-2">
          <label className="color-primary">{t("Select Agreement")}</label>
          <CustomSelect
            className="w-full"
            name="agreement"
            onChange={(e) => { setSelectedAgreement(e.value) }}
            value={
              selectedAgreement === null ? "" : undefined
            }
            options={agreements?.map((agreement) => {
              return ({
                label: agreement.title,
                value: agreement._id,
              });
            })}
          />
        </div>
        <div className="row col-md-auto align-content-end m-2" >
          <Button onClick={()=>{
            setSelectedAgreement(null);
          }}>
            {t("Clear")}
          </Button>
        </div>
      </AvForm>
      <Card className="p-3 mt-4">
        {refs &&
          <Table className="table table-hover text-center">
            <Thead>
              <Tr >
                <Th className="py-2 color-primary text-start">{t("Name")}</Th>
                <Th className="py-2 color-primary text-start">{t("Parent Name")}</Th>
                <Th className="py-2 color-primary text-start">{t("Platform")}</Th>
                <Th className="py-2 color-primary text-start">{t("Login")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Referrals data={(refs ?? [])?.filter((ref) => {
                if (selectedAgreement) {
                  return ref.agreementId === selectedAgreement;
                }
                if (platform !== "") {
                  return ref.platform === platform;
                }
                return true;
              })} filter={selectedAgreement} />
            </Tbody>
          </Table>}
      </Card>
    </Container >
  );
};

export default withTranslation()(Referral);
