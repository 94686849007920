/* eslint-disable no-debugger */
import React, { 
  memo,
  useEffect, 
  useState 
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { 
  Row, 
  Col, 
  Button, 
  Modal, 
  ModalBody, 
  ModalHeader 
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import validatePositiveInputs from "helpers/validatePositiveInputs";
import { updateSharedIbAgreement } from "store/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";

const getAccountTypeName = (accountTypeId, accountTypes) => {
  return accountTypes?.find((type) => type._id === accountTypeId)?.title;
};

const getAccountType = (accountTypeId, accountTypes) => {
  return accountTypes?.find((type) => type._id === accountTypeId);
};

const AgreementTotal = ({ total, accountTypeName, totalIdx }) => {
  const { t } = useTranslation();
  return (<Row key={total._id} className="my-1 align-items-center">
    <Col md="3">
      {accountTypeName}
    </Col>
    <Col>
      <AvField
        name={`totals[${totalIdx}].accountTypeId`}
        value={total.accountTypeId}
        type="hidden"
      />
      <AvField
        name={`totals[${totalIdx}].rebate`}
        value={`${total.rebate ?? 0}`}
        bsSize="sm"
        type="string"
        errorMessage={t("Invalid value!")}
        validate={{ required: { value: true } }}
        onKeyPress={(e) => validatePositiveInputs(e)}
      />
    </Col>
    <Col>
      <AvField
        className="mt-3"
        name={`totals[${totalIdx}].commission`}
        value={`${total.commission ?? 0}`}
        bsSize="sm"
        type="string"
        errorMessage={t("Invalid value!")}
        validate={{ required: { value: true } }}
        onKeyPress={(e) => validatePositiveInputs(e)}
      />
    </Col>
  </Row>);
};

const InvestorForm = ({ val, valIdx, agreementName, memberIdx }) => {
  const { t } = useTranslation();
  return (
    <Accordion.Item key={val._id} eventKey={valIdx}>
      <Accordion.Header>
        <Row className="justify-content-center mb-2 fw-bold gx-0">
          <Col md="12" className="text-center">
            {agreementName}
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          <Col md="3" className="text-center">
            {t("PAMM Profit Share")} %
            <AvField
              name={`members[${memberIdx}].values[${valIdx}].accountTypeId`}
              value={val.accountTypeId}
              type="hidden"
            />
          </Col>
          <Col md="9" className="text-center">
            <AvField
              name={`members[${memberIdx}].values[${valIdx}].pamProfitShare`}
              type="string"
              value={val.pamProfitShare}
              errorMessage={t("Invalid Profit Share!")}
              validate={{
                required: { value: true },
                min: { value: 0 }
              }}
              onChange={(e) => {
              }}
              onKeyPress={(e) => validatePositiveInputs(e)}
            />
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const AccountForm = ({ val, valIdx, agreementName, memberIdx, products, markups, member, setProductsModel, productsModel }) => {
  const { t } = useTranslation();
  const onChangeHandler = (e, type, memberIdx, valIdx) => {
    const { value } = e.target;
    const newObj = {};
    // productsModel is used from previous state. So, we need to update it with the new value
    for (const key in productsModel) {
      if (
        key.includes(
          `members[${memberIdx}]#values[${valIdx}]#products#`
        ) && key.endsWith(`#${type}`)
      ) {
        newObj[key] = parseFloat(value);
      }
    }
    setProductsModel((prev) => {
      return {
        ...prev,
        ...newObj,
      };
    });
  };
  return (<Accordion.Item key={val._id} eventKey={valIdx}>
    <Accordion.Header>
      <Row className="w-100 my-1 align-items-center my-0">
        <Col md="3">
          {agreementName}
        </Col>
        <Col>
          <AvField
            name={`members[${memberIdx}].values[${valIdx}].accountTypeId`}
            value={val.accountTypeId}
            type="hidden"
          />
          <AvField
            name={`members[${memberIdx}].level`}
            value={String(member.level)}
            type="hidden"
          />
          <AvField
            name={`members[${memberIdx}].customerId`}
            value={member.customerId._id}
            type="hidden"
          />
          <AvField
            name={`members[${memberIdx}].values[${valIdx}].rebate`}
            value={`${val.rebate ?? 0}`}
            bsSize="sm"
            type="string"
            errorMessage={t("Invalid value!")}
            validate={{ required: { value: true } }}
            onChange={(e) => onChangeHandler(e, "rebate", memberIdx, valIdx)}
          />
        </Col>
        <Col>
          <AvField
            name={`members[${memberIdx}].values[${valIdx}].commission`}
            value={`${val.commission ?? 0}`}
            bsSize="sm"
            type="string"
            errorMessage={t("Invalid value!")}
            validate={{ required: { value: true } }}
            onChange={(e) => onChangeHandler(e, "commission", memberIdx, valIdx)}

          />
        </Col>
      </Row>
    </Accordion.Header>
    <Accordion.Body>
      {products?.map((prod, prodIdx) => (
        <Row
          key={prodIdx}
          className="my-1 align-items-center"
        >
          <Col md="3">{prod}</Col>
          <Col>
            <AvField
              name={`members[${memberIdx}].values[${valIdx}].products.${prod}.rebate`}
              value={
                `${productsModel[
                  `members[${memberIdx}]#values[${valIdx}]#products#${prod}#rebate`
                ] ?? 0}`
              }
              bsSize="sm"
              type="string"
              errorMessage={t("Invalid value!")}
            />
          </Col>
          <Col>
            <AvField
              name={`members[${memberIdx}].values[${valIdx}].products.${prod}.commission`}
              value={
                `${(productsModel[
                  `members[${memberIdx}]#values[${valIdx}]#products#${prod}#commission`
                ] ?? 0)}`
              }
              bsSize="sm"
              type="string"
              errorMessage={t("Invalid value!")}
            />
          </Col>
        </Row>
      ))}
      <Row>
        <Col md="3">Markup</Col>
        <Col>
          <AvFieldSelect
            name={`members[${memberIdx}].values[${valIdx}].markup`}
            options={(markups || []).map((obj)=>{
              return ({
                label: `${obj}`, 
                value: obj
              });
            })} 
          />
        </Col>
      </Row>
    </Accordion.Body>
  </Accordion.Item>)
  ;
};

const MemoizedAgreementTotal = memo(AgreementTotal);
const MemoizedAccountsForm = memo(AccountForm);

const EditSharedIbModal = ({
  show,
  toggle: toggleModal,
  accountTypes,
  products,
  agreement,
  markups,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [productsModel, setProductsModel] = useState({});
  const [ibRebateTotals, setIBRebateTotals] = useState({});
  const [submissionFailure, setSubmissionFailure] = useState(false);
  const [error, setError] = useState(null);

  const { submitting } = useSelector((state) => state.ibAgreements);

  const reloadIbRebateTotals = () => {
    const newObj = {};
    (agreement?.members || []).forEach((member, memberIdx) => {
      (member?.values || []).forEach((val, valIdx) => {
        const accountTypeId = val.accountTypeId;
        const accountType = getAccountType(accountTypeId, accountTypes);
        if (accountType?.type === "INVESTOR") {
          const pammProfitShareKey = `members[${memberIdx}].values[${valIdx}].pamProfitShare`;
          newObj[pammProfitShareKey] = val.pamProfitShare;

        } else {
          const rebateId = `members-${memberIdx}#values-${valIdx}#rebate`;
          const commissionId = `members-${memberIdx}#values-${valIdx}#commission`;

          if (!ibRebateTotals[accountTypeId]) {
            ibRebateTotals[accountTypeId] = {};
          }

          ibRebateTotals[accountTypeId][rebateId] = val.rebate;
          ibRebateTotals[accountTypeId][commissionId] = val.commission;

          (products || []).forEach((prod) => {
            const prodRebateModel = `members[${memberIdx}]#values[${valIdx}]#products#${prod}#rebate`;
            const prodCommissionModel = `members[${memberIdx}]#values[${valIdx}]#products#${prod}#commission`;
            newObj[prodRebateModel] = val?.products?.[prod]?.rebate;
            newObj[prodCommissionModel] = val?.products?.[prod]?.commission;
          });
        }
      });
    });
    setProductsModel(newObj);
  };

  useEffect(() => {
    !submitting && show && toggle();
  }, [submitting]);

  useEffect(() => {
    setSubmissionFailure(false);
  }, []);

  useEffect(()=> {
    if (agreement && show) {
      reloadIbRebateTotals();
    }
  }, [agreement, show]);

  const validateSharedAgreement = (params) => {
    const {
      title, totals, members
    } = params;
    if (!title || !totals || !members) {
      return setError("Invalid values found. Please check the form again!");
    }
    const totalsMap = new Map();
    for (let i = 0; i < totals.length; i++) {
      const total = totals[i];
      totalsMap.set(total.accountTypeId, {
        rebate: parseInt(total.rebate) || 0,
        commission: parseInt(total.commission) || 0,
      });
    }
    const membersMap = new Map();
    for (let i = 0; i < members.length; i++) {
      const member = members[i];
      let isMemberWiseTotalValid = true;
      for (let j = 0; j < member.values.length; j++) {
        const value = member.values[j];
        const key = value.accountTypeId;
        const rebate = parseInt(value.rebate) || 0;
        const commission = parseInt(value.commission) || 0;
        const products = value.products;
        // note that products is an object. So, we need to iterate over it and verify that sum of all products is equal to the rebate and commission
        let maxRebate = 0;
        let maxCommission = 0;
        for (const prod in products) {
          if (!products[prod].rebate) {
            products[prod].rebate = 0;
          }
          if (!products[prod].commission) {
            products[prod].commission = 0;
          }
          const currentRebate = parseInt(products[prod].rebate) || 0;
          const currentCommission = parseInt(products[prod].commission) || 0;
          if (currentRebate > maxRebate) {
            maxRebate = currentRebate;
          }
          if (currentCommission > maxCommission) {
            maxCommission = currentCommission;
          }
        }
        if (maxRebate > rebate || maxCommission > commission) {
          console.log("Products sum is invalid.");
          isMemberWiseTotalValid = false;
          setError("Products sum is invalid.");
          break;
        }
        membersMap.set(key, {
          rebate: (membersMap.get(key)?.rebate || 0) + rebate,
          commission: (membersMap.get(key)?.commission || 0) + commission,
        });
        if (
          totalsMap.get(key)?.rebate < membersMap.get(key)?.rebate ||
          totalsMap.get(key)?.commission < membersMap.get(key)?.commission
        ) {
          console.log("Member wise total is invalid.");
          isMemberWiseTotalValid = false;
          setError("Member wise total is invalid.");
          break;
        }
      }
      if (!isMemberWiseTotalValid) {
        return;
      }
    }
    setError(null);
    dispatch(
      updateSharedIbAgreement({
        id: agreement._id,
        title,
        totals,
        members,
      })
    );
  };

  const toggle = () => {
    setSubmissionFailure(false);
    setError(null);
    reloadIbRebateTotals();
    toggleModal();
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered={true} scrollable={true} onClosed={() => {
      setSubmissionFailure(false);
      setError(null);
    }}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Edit Shared IB Agreement")}
      </ModalHeader>
      <ModalBody>
        {error && (
          <div
            style={{
              display: "block",
              color: "white",
              marginBottom: 10,
              padding: 10,
              borderRadius: 5,
              backgroundColor: "#f65854"
            }}
            className="formValidationErrMessage"
          >
            {error}
          </div>
        )}
        <AvForm
          validationEvent={["onChange", "onInput", "onBlur"]}
          onValidSubmit={(e, v) => {
            const { title, totals, members } = v;
            setSubmissionFailure(false);
            const newTotals = totals.map((total) => {
              return {
                accountTypeId: total.accountTypeId,
                rebate: parseFloat(total.rebate),
                commission: parseFloat(total.commission),
              };
            });
            const newMembers = members.map((member) => {
              return {
                customerId: member.customerId,
                level: parseInt(member.level),
                values: member.values.map((value) => {
                  const accountTypeId = value.accountTypeId;
                  const accountType = getAccountType(accountTypeId, accountTypes);
                  if (accountType?.type === "INVESTOR") {
                    return {
                      accountTypeId,
                      pamProfitShare: parseFloat(value.pamProfitShare),
                    };
                  } else {
                    return {
                      accountTypeId,
                      rebate: parseFloat(value.rebate),
                      commission: parseFloat(value.commission),
                      products: Object.keys(value.products).reduce((acc, prod) => {
                        acc[prod] = {
                          rebate: parseFloat(value.products[prod].rebate),
                          commission: parseFloat(value.products[prod].commission),
                        };
                        return acc;
                      }, {}),
                    };
                  }
                }),
              };
            });
            validateSharedAgreement({
              title,
              totals: newTotals,
              members: newMembers,
            });
          }}
          onInvalidSubmit={(e, v) => {
            setSubmissionFailure(true);
          }}
        >
          <AvField
            name={"title"}
            value={agreement.title}
            label={t("Agreement name")}
            className="mb-3"
            type="text"
            errorMessage={t("Required!")}
            validate={{ required: { value: true } }}
          />
          <Row className="justify-content-start">
            <Col md="3">{t("Type")}</Col>
            <Col>{t("Total Rebate")}</Col>
            <Col>{t("Total Commission")}</Col>
          </Row>
          {agreement.totals?.map((total, totalIdx) => (
            <MemoizedAgreementTotal key={totalIdx} total={total} accountTypeName={
              getAccountTypeName(total.accountTypeId, accountTypes)
            } totalIdx={totalIdx} />
          ))}
          <hr className="my-3" />
          <Accordion className="mb-3" alwaysOpen>
            {agreement?.members
              ?.sort((a, b) => (a.level > b.level ? 1 : -1))
              .map((member, memberIdx) => (
                <Accordion.Item key={member._id} eventKey={memberIdx}>
                  <Accordion.Header>
                    {member.customerId.firstName} {member.customerId.lastName}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion className="my-1" alwaysOpen>
                      {member?.values?.map((val, valIdx) => (
                        (getAccountType(val.accountTypeId, accountTypes)?.type === "INVESTOR") ? 
                          <InvestorForm key={val._id} val={val} valIdx={valIdx} agreementName={getAccountTypeName(val.accountTypeId, accountTypes)} memberIdx={memberIdx} />
                          : (
                            <MemoizedAccountsForm
                              key={val._id}
                              val={val}
                              valIdx={valIdx}
                              agreementName={getAccountTypeName(val.accountTypeId, accountTypes)}
                              memberIdx={memberIdx}
                              products={products}
                              markups={markups}
                              member={member}
                              productsModel={productsModel}
                              setProductsModel={setProductsModel}
                            />
                          )
                      ))}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>
          <Button type="submit" disabled={submitting}>
            {t("Submit")}
          </Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default memo(EditSharedIbModal);
