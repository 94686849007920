import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, ModalBody, ModalHeader,
} from "reactstrap";
import AgreementDetails from "../Partnership/AgreementDetails";
import { fetchAccountTypes } from "store/actions";

export default function ViewAgreementModal({
  show, toggle, agreement,
}) {

  const { accountTypes = [] } = useSelector((state) => state?.tradingAccountReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccountTypes());
  }, []);

  return (
    <Modal isOpen={show} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {agreement?.title}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <AgreementDetails agreement={agreement} accountTypes={accountTypes} />
      </ModalBody>
    </Modal>
  );
}
