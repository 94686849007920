import React, { 
  useEffect,
  useState,
} from "react";
import { withTranslation } from "react-i18next";
import {
  connect, useDispatch
} from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { makeWinnerAPI } from "apis/competitions";
import { showErrorNotification } from "store/actions";
import { getCustomerAccounts } from "apis/tradingAccounts";

function CompetitionRewardModal(props) {
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (props.values?.customer?.customerId){
        const res = await getCustomerAccounts(props.values?.customer?.customerId);
        if (res){
          setAccounts(res.docs);
        }
      }
    })();
  }, [props.values?.customer?.customerId]);

  return (
    <React.Fragment >
      <Modal isOpen={props.show} toggle={props.toggleShowModal} centered={true}>
        <ModalHeader toggle={props.toggleShowModal} tag="h4">
          {props.t("Competition Reward")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={async (e, v) => {
              setLoading(true);
              const res = await makeWinnerAPI({ 
                compId: props.values.competitionDetails._id,
                winnerId: props.values.customer.customerId,
                values: { ...v }
              });
              if (res.result) {
                window.location.reload(false);
              } else {
                dispatch(showErrorNotification(res.message));
              }
              setLoading(false);
              props.toggleShowModal(props);
            }}
          >
            <AvFieldSelect
              name="rewardType"
              label="Reward Type"
              placeholder="Select Reward Type"
              options={[
                {
                  label:"Bonus",
                  value: "BONUS" 
                },
                {
                  label: "Deposit",
                  value: "DEPOSIT"
                },
                {
                  label: "Credit",
                  value: "CREDIT"
                }
              ]}
              validate={{ required:true }}
            >      
            </AvFieldSelect>
            <AvField
              name="rewardAmount"
              type="number"
              label="Reward Amount"
              value={props.values?.competitionDetails?.reward}
            ></AvField>
            <AvFieldSelect
              name="account"
              label="Trading Account"
              placeholder="Select Trading Account"
              options={accounts?.map((acc) => {
                return {
                  label: `${acc.login} ${acc.type}`,
                  value: acc
                };
              })}
              validate={{ required:true }}
            >      
            </AvFieldSelect>
            <div className="d-flex align-items-center justify-content-center">
              <Button type="submit" className="text-center">
                Continue
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps, null)(withTranslation()(CompetitionRewardModal));
