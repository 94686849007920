
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Col,
  Row,
  Label,
  FormGroup,
  Input
} from "reactstrap";
import React, {
  useState,
  useEffect,
} from "react";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import moment from "moment";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { addCompetition } from "apis/competitions";
import { languageOptions } from "constants/userEmails";
import { fetchCampaignTemplates } from "store/actions";

function CompetitionForm(props) {
  const create = true;
  const competitionPermissions = useSelector(state => state.Profile.competitionPermissions || {});
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  console.log("accountTypes : ", accountTypes);
  const [addModal, setAddUserModal] = useState(false);
  const [state, setState] = useState({});
  const [file, setFile] = useState({});
  const [clientType, setClientType] = useState("IB");
  const [competitionType, setCompetitionType] = useState([
    "TOTAL_DEPOSITS",
    "TOTAL_WITHDRAWALS",
    "TOTAL_LIVE_CLIENTS",
    "TOTAL_SUB_IB",
  ]);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [language, setLanguage] = useState(languageOptions[0].value);
  const [loading, setLoading] = useState(false);

  const clientTypes = ["LIVE"];
  const templates = useSelector(state => state.campaignTemplates.docs);
  const dispatch = useDispatch();
  const handleFetchCampaignTemplates = (page, limit) => {
    dispatch(fetchCampaignTemplates({
      page,
      limit
    }));
  };
  useEffect(() => {
    handleFetchCampaignTemplates(1, 100);
  }, []);

  useEffect(() => {
    switch (clientType) {
      case "DEMO":
        setCompetitionType(["TOTAL_PROFIT"]);
        break;
      case "LIVE":
        setCompetitionType(["TOTAL_DEPOSITS", "TOTAL_LOTS_CLOSED", "TOTAL_PROFIT"]);
        break;
      case "IB":
        setCompetitionType([
          "TOTAL_DEPOSITS",
          "TOTAL_WITHDRAWALS",
          "TOTAL_LIVE_CLIENTS",
          "TOTAL_SUB_IB",
          "TOTAL_LOTS_CLOSED",
        ]);
        break;

      default:
        break;
    }
  }, [clientType]);

  const toggleAddModal = () => {
    setAddUserModal(!addModal);
  };

  const handleAddCompetition = async (event, values) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("image", file);
    for (const key in values) {
      if (key !== "image") {
        formData.append(key, values[key]);
      }
    }
    await addCompetition(formData);
    props.loadCompetitions();
    toggleAddModal();
    setLoading(false);
  };

  const handleChangeDate = async (value, ctx, input, cb) => {
    delete input.validations.min;
    const now = moment();
    const v = moment(value);
    if (!value) {
      cb("Date is required");
    }
    if (now.diff(v) > 0)
      cb("Can't choose past dates");
    else
      cb(true);
  };

  const validateFile = (value, ctx, input, cb) => {
    const extensions = ["png", "jpg", "jpeg", "pdf"];
    const extension = value.split(".")[1];
    if (extensions.includes(extension) || !value) {
      if (!value || file.size <= 2097152) {
        cb(true);
      } else cb("2mb maximum size");
    } else cb("Only images or PDF can be uploaded");
  };


  return (<>
    <Button
      color="primary"
      className={`btn btn-primary ${!create ? "d-none" : ""}`}
      onClick={toggleAddModal}>
      <i className="bx bx-plus me-1" />
      {props.t("Add New Competition")}
    </Button>
    <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
      <ModalHeader toggle={toggleAddModal} tag="h4">
        {props.t("Add New Competition")}
      </ModalHeader>
      <ModalBody >
        <AvForm
          className='p-4'
          onValidSubmit={(e, v) => {
            handleAddCompetition(e, v);
          }}
        >
          <Row>
            <Col md="12">
              <div className="mb-3">
                <AvField
                  name="name"
                  label={props.t("Name")}
                  placeholder={props.t("Enter Name")}
                  type="text"
                  errorMessage={props.t("Enter Name")}
                  validate={{ required: { value: true } }}
                />
              </div>
            </Col>
            <Col className="col-6 mb-3">
              <AvField
                type="datetime-local"
                name="dateFrom"
                label={props.t("Start Date")}
                // min={moment().format("YYYY-MM-DDTHH:mm")}
                validate={{
                  required: { value: true },
                }}
              >
              </AvField>
            </Col>
            <Col className="col-6 mb-3">
              <AvField
                type="datetime-local"
                name="dateTo"
                label={props.t("End Date")}
                min={moment().format("YYYY-MM-DDTHH:mm")}
                validate={{
                  required: { value: true },
                  custom: handleChangeDate
                }}
              >
              </AvField>
            </Col>
            <Col md="12">
              <div className="mb-3">
                <AvField
                  name="reward"
                  label={props.t("Reward")}
                  placeholder={props.t("Enter Reward")}
                  type="number"
                  errorMessage={props.t("Enter Reward")}
                  validate={{ required: { value: true } }}
                />
              </div>
            </Col>
            <Col md="12">
              <div className="mb-3">
                <AvField
                  name="totalWinnersNumber"
                  label={props.t("Total Number of Winners")}
                  placeholder={props.t("Enter Total Winners")}
                  type="number"
                  errorMessage={props.t("Enter Total Winners")}
                  validate={{ required: { value: true } }}
                />
              </div>
            </Col>
            <Col md="12">
              <div className="mb-3">
                <AvFieldSelect
                  name="clientType"
                  label={props.t("Client Type")}
                  placeholder={props.t("Select Client Type")}
                  options={clientTypes.map((type) => ({
                    value: type,
                    label: props.t(type)
                  }))}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: props.t("Client Type is required")
                    }
                  }}
                  defaultValue={clientType}
                  onChange={(v) => {
                    setClientType(v);
                  }}
                />
              </div>
            </Col>
            <Col md="12">
              <div className="mb-3">
                <AvFieldSelect
                  name="competitionType"
                  label={props.t("Competition Type")}
                  placeholder={props.t("Select Competition Type")}
                  options={competitionType.map((type) => ({
                    value: type,
                    label: props.t(type)
                  }))}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: props.t("Competition Type is required")
                    }
                  }}
                  defaultValue={competitionType[0]}
                />
              </div>
            </Col>
            {clientType === "LIVE" && <>
              <Col md="12">
                <div className="mb-3">
                  <AvFieldSelect
                    name="accountTypeId"
                    label={props.t("Account Type")}
                    placeholder={props.t("Select Account Type")}
                    options={accountTypes?.filter(t => t.type === "LIVE")?.map((type) => ({
                      value: type._id,
                      label: `${type.title} ${type.type} ${type.platform}`
                    }))}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: props.t("Account Type is required")
                      }
                    }}
                  />
                </div>
              </Col>
              <Col md="12">
                <AvCheckboxGroup inline name="block">
                  <AvCheckbox label={props.t("Block Deposit")} value="blockDeposit" />
                  <AvCheckbox label={props.t("Block Withdraw")} value="blockWithdraw" />
                </AvCheckboxGroup>
              </Col>
              <Col md="12">
                <AvField
                  name="minDepositToJoin"
                  type="number"
                  placeholder={props.t("Min Deposit To Join")}
                  label={props.t("Min Deposit")}
                >

                </AvField>
              </Col>
            </>}
            <Row>
              <Col md={6}>
                <AvFieldSelect
                  name="templateId"
                  label={props.t("Template")}
                  options={templates.map((template) => ({
                    value: template.id,
                    label: template.title
                  })) || []}
                  onChange={(e) => {
                    setSelectedTemplateId(e);
                  }}
                  required
                />
              </Col>
              <Col md={6}>
                <AvFieldSelect
                  name="language"
                  label={props.t("Language")}
                  options={languageOptions}
                  value={language}
                  onChange={(e) => setLanguage(e)}
                  required
                />
              </Col>
            </Row>
            {/* <Col className="col-12">
              <div className="mb-3">
                <Label>{props.t("Image")}</Label>
                <AvField
                  name="image"
                  type="file"
                  errorMessage={props.t("Please upload an image for the competition")}
                  validate={{
                    required: { value: true },
                    custom: validateFile
                  }}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
            </Col> */}
            <Col md="12">
              <div className="mb-3">
                <AvField
                  style={{
                    height: "150px"
                  }}
                  name="cpText"
                  label={props.t("Client Portal Text")}
                  placeholder={props.t("Enter Client Portal Text")}
                  type="textarea"
                  errorMessage={props.t("Enter Client Portal Text")}
                  validate={{ required: { value: true } }}
                />
              </div>
            </Col>
          </Row>
          <div className='text-center pt-3 p-2'>
            {loading ? <Loader /> : <Button disabled={loading} type="submit" color="primary" className="">
              {props.t("Add")}
            </Button>}
          </div>
        </AvForm>
        {
          props.error && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2" />
              {props.t(props.error)}
            </UncontrolledAlert>
          )
        }
      </ModalBody>
    </Modal>
  </>);
}

export default CompetitionForm;