import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import {
    
  Button, Col, Container, Row, Modal, ModalBody, ModalHeader,
} from "reactstrap";
import { getMT5Markups } from "store/client/actions";

import Select from "react-select";
import AgreementDetails from "./AgreementDetails";
import { fetchAccountTypes, fetchProducts } from "store/actions";

const IBAgreementModal = ({
  show, toggle, agreements, selectedAgreement, setSelectedAgreement, confirmIBApprove
}) => {

  const dispatch = useDispatch();

  const { _id, recordId, stages } = useSelector((state) => state.clientReducer?.clientDetails);
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  const markups = useSelector((state) => state.dictionaryReducer.markups);
  const { products, deleting, clearingCounter } = useSelector((state) => state.ibAgreements);

  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = userData?.roleId?.permissions;

  const {ibAgrement} = permissions;


  useEffect(() => {
    dispatch(fetchAccountTypes({
      type: "LIVE",
    }));
    dispatch(fetchProducts());
  }, []);

  useEffect(() => {
  //   _id && dispatch(fetchAgreements({ customerId: _id }));
    dispatch(getMT5Markups({ clientId: _id }));
  }, [_id, clearingCounter]);

  useEffect(() => {
    agreements && setSelectedAgreement(agreements[0]);
  }, [agreements]);


  const handleSubmit = () => {
    confirmIBApprove();
    toggle();
  };

  const { layoutMode } = useSelector(state => state.Layout);

  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#495057",
      padding: 0,
      paddingRight: "5px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none"
    }),
    control: (provided) => {
      if (layoutMode === "dark") {
        return {
          ...provided,
          backgroundColor: "#19283B",
          border: 0,
          boxShadow: "0 0.125rem 0.25rem #0B182F",
          color: "#adb5bd",
          height: "100%",
        };
      }
      return {
        ...provided,
        height: "100%",
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#242632" : "white",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
      flexDirection: "row",
      alignItems: "center",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        flexDirection: "row",
        alignItems: "center",
        color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      };
    },
  };
  return (
    <Modal isOpen={show} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        Select IB Agreement
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <Container fluid className="gx-4">
          <>
            <Row className="justify-content-between align-items-end">
              <Col md="6" className="d-flex align-items-end w-100">
                {agreements.length > 0 && <>
                  <AvForm className="d-flex justify-content-between align-items-center w-100 mb-3">
                    <label>Select IB Agreement</label>
                    <Select
                      className="w-50"
                      styles={customStyles}
                      name="agreement"
                      value={{ label: selectedAgreement?.title }}
                      options={agreements?.map(agreement => {
                        return {
                          label: agreement.title,
                          value: agreement,
                        };
                      }
                      )}
                      onChange={(e) => setSelectedAgreement(e.value)}
                    />
                  </AvForm>
                </>}
              </Col>
              <Col>
                <AgreementDetails
                  agreement={selectedAgreement}
                  accountTypes={accountTypes?.filter(acc => !["MAM_MASTER", "MAM_FEE", "DEMO", "INVESTOR"].includes(acc.type))}
                />
              </Col>
              <Button type="button" onClick={handleSubmit} disabled={!selectedAgreement}>Confirm</Button>

            </Row>
            
          </>
        </Container>
      </ModalBody>
    </Modal>
    
  );
};

export default withTranslation()(IBAgreementModal);
