import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";
import { editClientDetails } from "store/client/actions";
import { withTranslation } from "react-i18next";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { AvForm } from "availity-reactstrap-validation";

function SelectIBType({ t, id }) {
  const dispatch = useDispatch();
  const {
    clientDetails,
  } = useSelector(state => state.clientReducer);

  const [linkClientModal, setLinkClientModal] = useState(false);
  const [ibType, setIbType] = useState(null);

  const toggleModal = () => {
    setLinkClientModal(!linkClientModal);
  };

  const updateClient = (e, values) => {
    dispatch(editClientDetails({
      id,
      values,
    }));
    toggleModal();
  };

  const ibTypes = [
    {
      label: "No Type",
      value: "No_Type"
    },
    {
      label: "Silver",
      value: "Silver"
    },
    {
      label: "Sapphire",
      value: "Sapphire"
    },
    {
      label: "Platinum",
      value: "Platinum"
    },
    {
      label: "Legend",
      value: "Legend"
    },
    {
      label: "King",
      value: "King"
    },
    {
      label: "Emerald",
      value: "Emerald"
    },
    {
      label: "Gold IB",
      value: "Gold_IB"
    },
    {
      label: "Diamond IB",
      value: "Diamond_IB"
    },
    {
      label: "Bronze",
      value: "Bronze"
    },
    {
      label: "Normal IB",
      value: "Normal_IB"
    },
  ];


  return (
    <>
      <button
        type="button"
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggleModal}
      >
        {t("IB Type")}
      </button>
      <Modal isOpen={linkClientModal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>{t("IB Type")}</ModalHeader>
        <AvForm >
          <ModalBody>
            <Container>
              <Row>
                <Col md="3" className="text-center mb-3">
                  {t("Select IB Type")}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <AvFieldSelect
                    name={"IB_TYPE"}
                    value={clientDetails?.ibType}
                    options={ibTypes.map((obj) => ({
                      label: obj.label,
                      value: obj.value,
                    }))}
                    onChange={(v) => setIbType(v)}
                  />
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModal} color="secondary">
              {t("No")}
            </Button>
            <Button onClick={(e) => updateClient(e, { ibType })} color="primary" disabled={!ibType}>
              {t("Submit")}
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </>
  );
}

export default withTranslation()(SelectIBType);
