import React from "react";
import {
  connect
} from "react-redux";
import { CardBody } from "reactstrap";
import LinkClient from "../LinkClient";
// import LinkIb from "../LinkIb";
import UnLinkIb from "../unLinkIb";
import UnLinkClients from "../unLinkClients";
import ConvertToIb from "../ConvertToIb";
import AddSharedAgreement from "../AddSharedAgreement";
import AddMasterAgreement from "../AddMasterAgreement";
import LinkIBV2 from "../LinkIBV2";
import UnLinkIbV2 from "../unLinkIbV2";
import SelectIBType from "../selectIBType";

const IbQuickActions = (props) => {
  const {
    clientDetails,
    clientId,
    ibAgrementPermissions,
    clientPermissions,
    t
  } = props;
  return (
    <CardBody className="quick-actions-card">
      <p className="quick-actions-heading">{t("IB")}</p>
      <div className="btn-container">
        {clientDetails.fx?.isIb && ibAgrementPermissions.update && <LinkClient clientId={clientId} />}
        {/* <LinkIb
          clientId={clientId}
          isClient={clientDetails.fx?.isClient}
          isIb={clientDetails.fx?.isIb}
          isLead={clientDetails.isLead}
        /> */}
        {ibAgrementPermissions.update && 
          <LinkIBV2
            clientId={clientId}
            isClient={clientDetails.fx?.isClient}
            isIb={clientDetails.fx?.isIb}
            isLead={clientDetails.isLead}
            parentId={clientDetails.parentId?._id}
          />
        }
        {clientDetails.parentId?._id && ibAgrementPermissions.update && <UnLinkIbV2
          link={clientDetails.parentId}
          clientId={clientId}
          isLead={clientDetails.isLead}
        />}
        {clientDetails.parentId?._id && ibAgrementPermissions.update && <UnLinkIb
          link={clientDetails.parentId}
          clientId={clientId}
          isLead={clientDetails.isLead}
        />}
        {/* {clientDetails.fx?.isIb && <UnLinkClients clientId={clientId} />} */}
        {clientDetails.fx?.isIb && ibAgrementPermissions?.create &&
          <>
            <AddSharedAgreement clientId={clientId} />
            <AddMasterAgreement clientId={clientId} />
          </>}
        {!clientDetails.fx?.isIb && ibAgrementPermissions.update && (
          <ConvertToIb
            convertToIbDetails={props?.convertToIb}
            id={props?.clientDetails?._id}
            kyc={props?.clientDetails?.stages?.kycApproved}
            isLead={props.clientDetails.isLead}
            isIb={props.clientDetails.fx?.isIb}
          />
        )}
        {clientDetails.fx?.isIb && clientPermissions.update && (
          <SelectIBType convertToIbDetails={props?.convertToIb}
            id={props?.clientDetails?._id}
            kyc={props?.clientDetails?.stages?.kycApproved}
            isLead={props.clientDetails.isLead}
            isIb={props.clientDetails.fx?.isIb} />
        )}
      </div>
    </CardBody>
  );
};

const mapStateToProps = (state) => ({
  ibAgrementPermissions: state.Profile.ibAgrementPermissions || {},
});

export default connect(mapStateToProps, null)(IbQuickActions);