import {
  Fragment
} from "react";
import { useTranslation } from "react-i18next";
import { Tr, Td } from "react-super-responsive-table";

const Referrals = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      {data ?
        data.map((parent) =>
          <Fragment key={parent._id}>        
            <Tr>
              
              <Td className="py-2 text-start">{parent.firstName} {parent.lastName}</Td>
              <Td className="py-2 text-start">{parent.fx.isIb ? t("Sub IB") : t("Client")}</Td>
              <Td className="py-2 text-start">{parent.platform}</Td>
              {/* acc num */}
              <Td className="py-2 text-start">
                {parent.login} 
              </Td>
            </Tr>
          </Fragment>
        )
        : <Tr><Td colSpan="4">{t("No referrals found.")}</Td></Tr>}
    </>
  );
};

export default Referrals;