import React, { useEffect, useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import Todos from "./Todos";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { isCurrentUserManagerOfAssignedAgent } from "apis/teams";
import { useTranslation } from "react-i18next";

function Notes() {
  const { clientId } = useParams();
  const {t} = useTranslation();
  const { _id, roleId } = JSON.parse(localStorage.getItem("authUser"));
  const customer = useSelector((state) => state.clientReducer.clientDetails);
  const [isManager, setIsManager] = useState(null);
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const permissions = userData?.roleId?.permissions;
  const {remindersTodos} = permissions;

  useEffect(async () => {
    if (customer.agent?._id === undefined) return;
    // eslint-disable-next-line no-console
    setIsManager(await isCurrentUserManagerOfAssignedAgent(customer.agent._id).catch((err) => console.error(err)));
  }, [customer.agent?._id]);

  return (
    <>
      {remindersTodos.get || remindersTodos.getAssigned ? (
        <>
          <Todos clientId={clientId} type={0} currentUserId={_id} />
          <Todos clientId={clientId} type={1} currentUserId={_id} />
          <Todos clientId={clientId} type={2} currentUserId={_id} />
          {(roleId.permissions.remindersTodos.get || customer.agent?._id === _id || isManager) &&
            <Todos clientId={clientId} currentUserId={_id} type={3} />}
        </>
      ) : (
        <div className="text-center">
          <h2>{t("You Do Not Have The Required Permission")}</h2>
        </div>
      )}
    </>
  );
}

export default Notes;
