import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import classnames from "classnames";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

// i18n 
import { useTranslation, withTranslation } from "react-i18next";
import DocumentUpload from "./DocumentUpload";
import DocumentList from "./DocumentList";
import { fetchDocsStart } from "store/documents/actions";
import { useParams } from "react-router-dom";
import CorpDocuments from "./CorpDocuments";
import ApproveKYC from "./ApproveKYC";
import RejectedDocuments from "./RejectedDocuments";
// import { fetchClientStages } from "store/client/actions";

function ClientDetails(props) {
  const { clientId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isUpdated, setIsUpdated] = useState(false);

  const loadDocs = (clientId) => {
    dispatch(fetchDocsStart(clientId));
  };

  useEffect(() => {
    loadDocs(clientId);
  }, [isUpdated]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const loadTabs = () => [
    {
      tabId: "1",
      navLinkName: t("Documents"),
      component: <DocumentList setIsUpdated={setIsUpdated} />,
    },
    {
      tabId: "2",
      navLinkName: t("Rejected Documents"),
      component: <RejectedDocuments setIsUpdated={setIsUpdated} />,
    },
    {
      tabId: "3",
      navLinkName: t("Approve KYC Stage"),
      component: <ApproveKYC t={t} />,
    },
  ];

  const tabs = loadTabs().filter((item) => !item.hidden);
  const [activeTab, setactiveTab] = useState(
    tabs.length > 0 ? tabs[0].tabId : "1"
  );

  return (
    <React.Fragment>
      {
        <div className="">
          <div className="container-fluid">
            <div className="">
              <Row>
                <Col md="12" sm="12" xs="12">
                  {props.clientDetails?.isCorporate ? <CorpDocuments {...props} /> : <DocumentUpload {...props} />}
                </Col>
                {/* <Col md="12" sm="12" xs="12">
                  <DocumentList />
                </Col> */}
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <Nav tabs>
                        {tabs.map((tabItem) => (
                          <>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === tabItem.tabId,
                                })}
                                onClick={() => {
                                  toggle(tabItem.tabId);
                                }}
                              >
                                {tabItem.navLinkName}
                              </NavLink>
                            </NavItem>
                          </>
                        ))}
                      </Nav>
        
                      <TabContent activeTab={activeTab} className="p-3 text-muted">
                        {tabs.map((tabItem) => (
                          <>
                            <TabPane tabId={tabItem.tabId}>
                              <Row>
                                <Col sm="12">{tabItem.component}</Col>
                              </Row>
                            </TabPane>
                          </>
                        ))}
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

          </div>
        </div>
      }
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.clientReducer.loading,
  clientDetails: state.clientReducer.clientDetails,

});

export default connect(mapStateToProps, null)(withTranslation()(ClientDetails));