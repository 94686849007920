import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col
} from "reactstrap";
import { fetchClientDetails, fetchClientsStart } from "store/client/actions";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import {
  fetchAgreements, fetchTradingAccounts, showErrorNotification, showSuccessNotification
} from "store/actions";
import AgreementDetails from "pages/ClientDetail/Partnership/AgreementDetails";
import CustomSelect from "components/Common/CustomSelect";
import { searchAllAccountsAPI } from "apis/tradingAccounts";
import AsyncAvFieldSelect from "components/Common/AsyncAvFieldSelect";
import { linkTradingAccountsToIb } from "apis/client";
import IbConfirmation from "./Confirmation/IbConfirmation";

function LinkIBV2({ clientId,  isLead, parentId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { linking } = useSelector((state) => state.clientReducer);
  const { agreements } = useSelector((state) => state.ibAgreements);
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  const accounts = useSelector((state) => state.tradingAccountReducer.accounts.docs);
  const [loading, setLoading] = useState(false);
  const [ibLogin, setIbLogin] = useState("");
  const [ib, setIb] = useState({});
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedAgreement, setSelectedAgreement] = useState("");
  const [linkClientModal, setLinkClientModal] = useState(false);
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [formValue, setFormValue] = useState({});

  useEffect(() => {
    linkClientModal && dispatch(fetchClientsStart({ limit: 999 }));
  }, [linkClientModal]);

  useEffect(() => {
    setSelectedAgreement("");
    selectedClientId && dispatch(fetchAgreements({ customerId: selectedClientId }));
  }, [selectedClientId]);

  const toggleModal = () => {
    setLinkClientModal(!linkClientModal);
  };

  
  const toggle = () =>{
    setSelectedAgreement("");
    setSelectedClientId("");
    setIbLogin("");
    toggleModal();
  };

  useEffect(() => {
    if (linking && linkClientModal) {
      setLinkClientModal(false);
    }
  }, [linking]);

  useEffect(() => {
    if (ibLogin) {
      setLoading(true);
      searchAllAccountsAPI({
        payload: {
          login: ibLogin,
          type: "IB",
        }
      }).then((res) => {
        if (res.result) {
          setIb(res.result);
        } else {
          setIb({});
        }
      }).catch(err => setIb({})).finally(() => {
        setLoading(false);
      });
    }
  }, [ibLogin]);

  useEffect(() => {
    if (ib?.customerId) { 
      setSelectedClientId(ib.customerId?._id);
      dispatch(fetchTradingAccounts({
        page: 1,
        limit: 50,
        customerId: clientId,
      }));
    } else {
      setSelectedClientId("");
    }
  }, [ib]);

  const handleSubmit = (obj) => {
    setLoading(true);
    linkTradingAccountsToIb(obj).then((res) => {
      if (res.isSuccess) {
        dispatch(showSuccessNotification("Successfully linked to IB"));
        dispatch(fetchClientDetails(clientId));
        toggle();
      } else {
        throw new Error(res.message);
      }
    }).catch(err => {
      console.log(err);
      dispatch(showErrorNotification("Error: " + err.message));
    }).finally(() => {
      setLoading(false);
      setFormValue({});
    });
  };


  const submitHandler = (v) => {
    let obj = {
      ibLogin,
      agreementId: selectedAgreement?._id,
      platform: ib.platform,
      clientId,
    };
    if (v.accounts) {
      obj.logins = v.accounts.filter( a => !a.isDisabled).map(a => a.value.login);
    } else {
      obj.logins = [];
    }
    setFormValue(obj);
    if (parentId !== ib.customerId?._id && parentId !== clientId) {
      setNeedsConfirmation(true);
    } else {
      handleSubmit({
        ...obj,
        changeParent: clientId !== parentId,
      });
    } 
  };

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggle}
      >
        {t("Link to IB")}
      </button>
      {
        needsConfirmation && <IbConfirmation
          needsConfirmation={needsConfirmation}
          setNeedsConfirmation={setNeedsConfirmation}
          loading={loading}
          submitHandler={(changeParent) => {
            handleSubmit({
              ...formValue,
              changeParent
            });
          }}
        />
      }
      <Modal size="xl" isOpen={linkClientModal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {t("Link IB")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              submitHandler(v);
            }}
          >

            <Row className="mb-3">
              <Col>
                <AvField
                  name="ibLogin"
                  label={t("IB Account")}
                  placeholder={t("Enter IB Account")}
                  type="text"
                  errorMessage={t("Enter Valid IB Account")}
                  validate={{
                    required: { value: true }
                  }}
                  onChange={(e) => setIbLogin(e.target.value)}
                  value={ibLogin}
                />
              </Col>
              <Col>
                <AvField
                  name="ib"
                  label={t("IB")}
                  type="text"
                  errorMessage={t("Enter Valid IB Account")}
                  value={ib.customerId ? `${ib.customerId?.firstName} ${ib.customerId?.lastName}` : ""}
                  disabled
                />
              </Col>
              {
                selectedClientId && agreements && agreements.length > 0 && <Col className="mt-2" md="12">
                  <Label>{t("Agreements")}</Label>
                  <div>
                    <CustomSelect
                      isRequired
                      placeholder={t("Select agreement")}
                      onChange={(e) => {
                        setSelectedAgreement(e.value);
                      }}
                      isSearchable={true}
                      options={agreements?.map((agr) => (
                        {
                          label: agr.title,
                          value: agr
                        }
                      ))}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              }
              {selectedAgreement && <Col className="mt-3" md="12">
                <AgreementDetails agreement={selectedAgreement} accountTypes={accountTypes} />
              </Col>}
              {
                selectedAgreement && <Col className="mt-2" md="12">
                  <AsyncAvFieldSelect
                    name="accounts"
                    label={t("Accounts")}
                    placeholder={t("Select Accounts")}
                    isSearchable
                    isMulti
                    defaultOptions={accounts?.filter(a => a.type === "LIVE")?.map((item) => (
                      {
                        label: `${item.login} (${item.type}) | ${item.platform} | ${item.accountTypeId?.title}`,
                        value: item,
                        isDisabled: item.ibLink !== null,
                      }
                    ))}
                    classNamePrefix="select2-selection"
                  />
                </Col>
              }
            </Row>
            <div className='text-center pt-3 p-2'>
              <Button type="submit" color="primary" disabled={!selectedAgreement} 
              >
                {t("Link")}
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
}

export default (LinkIBV2);