import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { startCase } from "lodash";

function ReceiptModal(props){
  const { content, open, onClose, clientPhoto } = props;
  const baseUrl = process.env.REACT_APP_API_CRM_DOMAIN;
  return (
    <React.Fragment>
      <div>
        <Modal isOpen={open} toggle={onClose} centered={true} size={"lg"}>
          <ModalHeader toggle={onClose} tag="h4">
            {props.t(content?.type === "image" ? "Receipt" : "Details")}
          </ModalHeader>
          <ModalBody>
            {
              content?.type === "image" && !clientPhoto ? (
                <>
                  <img
                    src={`${baseUrl}/assets/${content.content}`}
                    alt="Receipt"
                    style={{ width: "100%" }}
                  />
                </>
              ) : clientPhoto ? (
                <>
                  <img
                    src={`${clientPhoto}`}
                    alt="Photo"
                    style={{ width: "100%" }}
                  />
                </>
              ) : (
                <>
                  <Row>

                    {Object.keys(content?.content || {}).map((key, i) => (
                      <div key={i} className={`col-${content?.content?.[key]?.length > 15 ? 12 : 6}`}>
                        <p className="mb-1">
                          <strong>{startCase(key ?? "")}</strong>
                        </p>
                        <p className="text-muted">{content?.content?.[key]}</p>
                      </div>

                    ))}
                  </Row>
                </>
              )
            }         
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(ReceiptModal);