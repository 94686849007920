import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import {
  fetchTradingAccounts, showErrorNotification, showSuccessNotification
} from "store/actions";
import { AvForm } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { unlinkTradingAccountFromIb } from "apis/client";

function unLinkIb({ clientId, isLead, t }) {
  const dispatch = useDispatch();
  const { linking } = useSelector((state) => state.clientReducer);
  const accounts = useSelector((state) => state.tradingAccountReducer.accounts.docs);
  const [loading, setLoading] = useState(false);
  const [linkClientModal, setLinkClientModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const toggleModal = () => {
    setLinkClientModal(!linkClientModal);
  };

  const toggle = () => {
    toggleModal();
    setLoading(false);
    setSelectedAccount(null);
  };

  const fetchData = async () => {
    dispatch(
      fetchTradingAccounts({
        page: 1,
        limit: 50,
        customerId: clientId,
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    if (linking && linkClientModal) {
      setLinkClientModal(false);
    }
  }, [linking]);

  const handleSubmit = (obj) => {
    setLoading(true);
    console.log(obj);
    unlinkTradingAccountFromIb(obj).then((res) => {
      if (res.isSuccess) {
        dispatch(showSuccessNotification("Successfully linked to IB"));
        toggle();
      } else {
        throw new Error(res.message);
      }
    }).catch(err => {
      console.log(err);
      dispatch(showErrorNotification("Error: " + err.message));
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <React.Fragment >
      <button
        type="button"
        disabled={isLead}
        className="btn btn-primary waves-effect waves-light w-100 me-1"
        onClick={toggle}
      >
        {t("Unlink Accounts")}
      </button>
      <Modal isOpen={linkClientModal} toggle={toggle} centered={true}>
        <AvForm
          className="needs-validation"
          autoComplete="off"
          onValidSubmit={(e, v) => {
            const obj = {
              login: v.account.login,
              ibLogin: v.account.ibLink.ibAccountId.login,
              agreementId: v.account.ibLink.agreementId,
              platform: v.account.platform,
            };
            handleSubmit(obj);
          }}
        >
          <ModalHeader toggle={toggle} tag="h4">
            {t("Unlink Accounts")}
          </ModalHeader>
          <ModalBody >
            <AvFieldSelect
              name="account"
              label={t("Account")}
              onChange={(e) => setSelectedAccount(e)}
              placeholder={t("Select Accounts")}
              required
              options={accounts?.filter( a => a?.ibLink !== null)?.map((item) => (
                {
                  label: `${item.login} (${item.type}) | ${item.platform} | ${item.accountTypeId?.title}`,
                  value: item,
                }
              ))}
              classNamePrefix="select2-selection"
            />
            {
              selectedAccount && <div className="mt-3">
                <h5 className="text-center">{t("IB Details")}</h5>
                <div>
                  <span className="fw-bold">{t("IB Name")}: </span>
                  <span>
                    {selectedAccount?.ibLink?.ibAccountId?.customerId?.firstName}
                    {" "} {selectedAccount?.ibLink?.ibAccountId?.customerId?.lastName}
                  </span>
                </div>
              </div>
            }
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary" disabled={linking || loading || !selectedAccount}>
              {t("Unlink")}
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
}

export default withTranslation()(unLinkIb);