import React, { useEffect, useState } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row, Col, Label
} from "reactstrap";
import { linkTradingAccountsToIb } from "apis/client";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAgreements, showErrorNotification, showSuccessNotification
} from "store/actions";
import { useTranslation } from "react-i18next";
import { searchAllAccountsAPI } from "apis/tradingAccounts";
import CustomSelect from "components/Common/CustomSelect";
import AgreementDetails from "pages/ClientDetail/Partnership/AgreementDetails";

export default function LinkAccount(props) {
  const { toggle, selectedAccount, show, fetchData } = props;
  const { linking } = useSelector((state) => state.clientReducer);
  const { agreements } = useSelector((state) => state.ibAgreements);
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [ibLogin, setIbLogin] = useState("");
  const [ib, setIb] = useState({});
  const [selectedAgreement, setSelectedAgreement] = useState("");

  useEffect(() => {
    if (ibLogin) {
      setLoading(true);
      searchAllAccountsAPI({
        payload: {
          login: ibLogin,
          type: "IB",
        }
      }).then((res) => {
        if (res.result) {
          setIb(res.result);
        } else {
          setIb({});
        }
      }).catch(err => setIb({})).finally(() => {
        setLoading(false);
      });
    }
  }, [ibLogin]);

  useEffect(() => {
    setSelectedAgreement("");
    ib.customerId?._id && dispatch(fetchAgreements({ customerId: ib.customerId?._id }));
  }, [ib]);

  const handleSubmit = (obj) => {
    setLoading(true);
    linkTradingAccountsToIb(obj).then((res) => {
      if (res.isSuccess) {
        dispatch(showSuccessNotification("Successfully linked to IB"));
        fetchData(1);
        toggle();
      } else {
        throw new Error(res.message);
      }
    }).catch(err => {
      console.log(err);
      dispatch(showErrorNotification("Error: " + err.message));
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Modal isOpen={show} size="lg" toggle={toggle} centered={true}>
      <AvForm
        className="needs-validation"
        autoComplete="off"
        onValidSubmit={(e, v) => {
          const obj = {
            logins: [selectedAccount?.login],
            ibLogin,
            agreementId: selectedAgreement?._id,
            platform: selectedAccount?.platform,
            changeParent: false,
            clientId: selectedAccount?.customerId?._id,
          };
          handleSubmit(obj);
        }}
      >
        <ModalHeader toggle={toggle} tag="h4">
          {t("Link Account")}
        </ModalHeader>
        <ModalBody >
          <Row>
            <AvField
              name="login"
              label={t("Account")}
              type="text"
              placeholder={t("Account")}
              required
              disabled
              value={selectedAccount?.login}
            />
          </Row>
          <AvField
            name="ibLogin"
            label={t("IB Account")}
            type="text"
            placeholder={t("IB Account")}
            required
            onChange={(e) => setIbLogin(e.target.value)}
            value={ibLogin}
          />
          {
            ib?.customerId?._id && agreements && agreements.length > 0 && <Col className="mt-2" md="12">
              <Label>{t("Agreements")}</Label>
              <div>
                <CustomSelect
                  isRequired
                  placeholder={t("Select agreement")}
                  onChange={(e) => {
                    setSelectedAgreement(e.value);
                  }}
                  isSearchable={true}
                  options={agreements?.map((agr) => (
                    {
                      label: agr.title,
                      value: agr
                    }
                  ))}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          }
          {selectedAgreement && <Col className="mt-3" md="12">
            <AgreementDetails agreement={selectedAgreement} accountTypes={accountTypes} />
          </Col>}
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary"
            disabled={linking || loading || !ibLogin || !selectedAgreement}
          >
            {t("Link")}
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}
