import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  AvForm, AvField
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import AvFieldSelect from "components/Common/AvFieldSelect";
import validatePositiveInputs from "helpers/validatePositiveInputs";

import { updateAccountType } from "store/actions";
import { useTranslation } from "react-i18next";

const PLATFORMS = ["MT5", "MT4", "CTRADER" ];
const TYPES = ["LIVE", "DEMO", "IB", "MERCHANT"];
const LEVERAGE = [1, 20, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
const CURRENCIES = ["USD", "EUR", "GBP", "EGP"];

function EditAccountType({ show, toggle, selectedAccountType }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updating, error } = useSelector((state) => state.tradingAccountReducer);
  const [leverageOptions, setLeverageOptions] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  
  useEffect(() => {
    if (selectedAccountType) {
      setCurrencies(selectedAccountType.currencies.map((currency) => currency.currency));
      setLeverageOptions(selectedAccountType.leverages);
    }
  }, [selectedAccountType]);

  useEffect(() => {
    (!updating && !error && show) && toggle();
  }, [updating]);

  const handleSubmit = (e, v) => {
    // add groupPath to currencies array
    v.currencies = v.currencies.reduce((acc, curr) => {
      acc.push({
        currency: curr,
        groupPath: v[`${curr}GroupPath`],
      });
      delete v[`${curr}GroupPath`];
      return acc;
    }, []);
    dispatch(updateAccountType(selectedAccountType._id, v));
  };

  return (
    <>
      <Modal isOpen={show} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {t("Edit Account Type")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='px-4'
            onValidSubmit={(e, v) => { handleSubmit(e, v) }}
          >
            <AvField
              name="title"
              label={t("Title")}
              placeholder={t("Select Title")}
              type="text"
              value={selectedAccountType?.title}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Title is required")
                }
              }}
            />
            <AvFieldSelect
              name="platform"
              label={t("Platform")}
              placeholder={t("Select Platform")}
              value={selectedAccountType?.platform}
              options={PLATFORMS.map((type) => ({
                value: type,
                label: t(type)
              }))}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Platform is required")
                }
              }}
            />
            <AvFieldSelect
              name="type"
              label="Type"
              placeholder={t("Select Type")}
              options={TYPES.map((type) => ({
                value: type,
                label: t(type)
              }))}
              value={selectedAccountType?.type}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Type is required")
                }
              }}
            />
            <AvFieldSelect
              name="leverages"
              label={t("Leverage Options")}
              placeholder={t("Select Leverage Options")}
              ismulti={true}
              value={selectedAccountType?.leverages}
              options={LEVERAGE.map((type) => ({
                value: type,
                label: t(type)
              }))}
              onChange={(e) => {
                setLeverageOptions(e);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Leverage options is required")
                }
              }}
            />
            <AvFieldSelect
              name="defaultLeverage"
              label={t("Default Leverage")}
              placeholder={t("Select Default Leverage")}
              options={leverageOptions?.map((type) => ({
                value: type,
                label: t(type)
              }))}
              // if default leverage is not in leverage options, set it to null
              value={leverageOptions?.includes(selectedAccountType?.defaultLeverage) ? selectedAccountType?.defaultLeverage : null}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Default leverage is required")
                }
              }}
            />
            <AvField
              name="sequence"
              label={t("Sequence")}
              placeholder={t("Select Sequence")}
              type="number"
              min="0"
              onKeyPress={(e) => {
                validatePositiveInputs(e);
              }}
              value={selectedAccountType?.sequence}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Sequence is required")
                }
              }}
            />
            <AvField
              name="accountsLimit"
              label={t("Limit Accounts Per User")}
              placeholder={t("Select Limit")}
              type="number"
              min="0"
              onKeyPress={(e) => {
                validatePositiveInputs(e);
              }}
              value={selectedAccountType?.accountsLimit}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Limit is required")
                }
              }}
            />
            <AvField
              name="minDeposit"
              label={t("Minimum Deposit")}
              placeholder={t("Select Minimum Deposit")}
              type="number"
              min="0"
              onKeyPress={(e) => {
                validatePositiveInputs(e);
              }}
              value={selectedAccountType?.minDeposit}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Minimum Deposit is required")
                }
              }}
            />
            <AvField
              name="minWithdrawal"
              label={t("Minimum Withdrawal")}
              placeholder={t("Select Minimum Withdrawal")}
              type="number"
              min="0"
              onKeyPress={(e) => {
                validatePositiveInputs(e);
              }}
              value={selectedAccountType?.minWithdrawal}
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Minimum Withdrawal is required")
                }
              }}
            />
            <AvFieldSelect
              name="currencies"
              label={t("Currencies")}
              placeholder={t("Select Currencies")}
              ismulti={true}
              options={CURRENCIES.map((type) => ({
                value: type,
                label: t(type)
              }))}
              onChange={(e) => {
                setCurrencies(e);
              }}
              value={currencies}  
              validate={{
                required: {
                  value: true,
                  errorMessage: t("Currencies is required")
                }
              }}
            />
            {
              currencies.length > 0 &&
              currencies.map((currency) => (
                <AvField
                  key={currency}
                  name={`${currency}GroupPath`}
                  label={`${currency} ${t("Group Path")}`}
                  placeholder={t(`${t("Select")} ${currency} ${t("group path")}`)}
                  type="text"
                  value={selectedAccountType?.currencies.find((curr) => curr.currency === currency)?.groupPath}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: `${currency} ${t("group path is required")}`
                    }
                  }}
                />
              ))
            }
            <div className='text-center mt-3 p-2'>
              {
                updating
                  ? <Loader />
                  : <Button disabled={updating} type="submit" color="primary">
                    {t("Edit")}
                  </Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

export default EditAccountType;