import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector, connect
} from "react-redux";
import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, Button,
} from "reactstrap";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import TableLoader from "components/Common/Loader";
import WinnerButton from "./WinnerButton";
import CompetitionRewardModal from "../Competition/CompetitionRewardModal";

function CompetitionStats(props) {
  const [rewardModalValues, setRewardModalValues] = useState({});
  const [competitionStats, setCompetitionStats] = useState([]);
  const [show, setShowModal] = useState(false);

  useEffect(()=>{
    setCompetitionStats(props.competitionStats);
  }, [props.competitionStats]);

  const toggleShowModal = () => {
    setShowModal(!show);
  };
  const columns = [
    {
      dataField: "name",
      text: props.t("Name"),
      formatter: (val) => `${val.firstName} ${val.lastName}`
    },
    {
      dataField: "email",
      text: props.t("Email"),
    
    },
    {
      dataField: "tradingAccounts",
      text: props.t("Accounts"),
      formatter: (val) => `${val?.logins?.toString() || "---"}`
    
    },
    {
      dataField: "totalAmount",
      text: props.t("Total Amount")
    },
    {
      dataField: "",
      text: props.t("Select Winner"),
      formatter: (val) => {
        return <>
          <WinnerButton 
            competitionDetails={{ ...props }}
            customer={{ ...val }}
            onClick= {(event, values)=>{
              toggleShowModal(values);
              setRewardModalValues(values);
            }}
            t={props.t}
          ></WinnerButton>
        </>;
      }
    }
  ];
  return (<>
    <Row>
      <Col className="col-12">
        <Card>
          <CardHeader className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between  align-items-center">
              <CardTitle className="color-primary">{props.t("Competition Stats")} ({props.t(props.name)})</CardTitle>
            </div>
            <h6 className="text-center">{props.t("Competition Stats only changes every 15 minutes")}</h6>
          </CardHeader>
          <CardBody>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-hover table-clients"
                >
                  <Thead className="text-center table-light">
                    <Tr>
                      {columns.map((column, index) =>
                        <Th data-priority={index} key={index}>
                          <span className="color-primary">{column.text}</span>
                        </Th>
                      )}
                    </Tr>
                  </Thead>
                  <Tbody className="text-center" style={{ fontSize: "13px" }}>
                    {props.loading && <TableLoader colSpan={4} />}
                    {!props.loading && competitionStats?.map((row, rowIndex) =>
                      <Tr key={rowIndex}>
                        {columns.map((column, index) =>
                          <Td key={`${rowIndex}-${index}`}
                            style={{
                              overflow: column.dataField === "actions" && "visible",
                              maxWidth: column.dataField === "actions" && "140px"
                            }}>
                            {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                          </Td>
                        )}
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <CompetitionRewardModal
        toggleShowModal={toggleShowModal}
        show={show}
        values={rewardModalValues}
        setCompetitionStats={setCompetitionStats}
        competitionStats={competitionStats}
      ></CompetitionRewardModal>
    </Row>
  </>);
}

export default CompetitionStats;