import React from "react";
import {
  Modal,
  ModalBody,
  Row,
  Col
} from "reactstrap";

export default function IbConfirmation(props) {
  const { needsConfirmation, setNeedsConfirmation, loading, submitHandler } = props;
  return (
    <Modal
      isOpen={needsConfirmation}
      toggle={() => needsConfirmation(!needsConfirmation)}
      centered={true}
    >
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-comment-alert-outline"
                style={{
                  fontSize: "9em",
                  color: "orange",
                }}
              />
              <h2>This will update the Master for this IB?</h2>
              {/* <h4>{"You won't be able to revert this!"}</h4> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                disabled={loading}
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={() => {
                  submitHandler(true);
                  setNeedsConfirmation(false);
                }}
              >
                Yes, Update it!
              </button>
              <button
                disabled={loading}
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={() => {
                  submitHandler(false);
                  setNeedsConfirmation(false);
                }}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
