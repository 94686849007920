import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector, connect
} from "react-redux";
import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Spinner, Input, Label
} from "reactstrap";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link, useLocation } from "react-router-dom";

import CustomPagination from "components/Common/CustomPagination";
import { MetaTags } from "react-meta-tags";
import { withTranslation } from "react-i18next";
import CompetitionForm from "./CompetitionForm";
import TableLoader from "components/Common/Loader";
import formatDate from "helpers/formatDate";
import {
  getCompetitions, getCompetitionStats, updateIsActive
} from "apis/competitions";
import CompetitionStats from "./CompetitionStats";
import { fetchAccountTypes } from "store/actions";

function CompetitionList(props) {
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    pageCounter: 0,
    prevPage: null,
    totalDocs: 0,
    totalPages: 0
  });
  const [compLoading, setCompLoading] = useState(false);
  const [selectedCompStats, setSelectedCompStats] = useState({ competitionStats: [] });
  const [statusLoading, setStatusLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = props;
  const loadCompetitions = async (page, limit) => {
    setLoading(true);
    const res = await getCompetitions({
      page,
      limit
    });
    setState({
      ...state,
      ...res
    });
    setLoading(false);
  };

  useEffect(() => {
    loadCompetitions(state.page, sizePerPage);
  }, []);

  const showCompetitionStats = async (comp) => {
    setCompLoading(true);
    const res = await getCompetitionStats(comp._id);
    setSelectedCompStats(res);
    setCompLoading(false);
  };

  useEffect(() => {
    dispatch(fetchAccountTypes({
      forCrm: true
    }));
    return () => {
      dispatch(fetchAccountTypes());
    };
  }, []);

  const updateActivation = async (item, index) => {
    setStatusLoading(true);
    const updateRes = await updateIsActive(item._id);
    if (updateRes.isSuccess){
      const st = state;
      st.docs[index].isActive = !item.isActive;
      setState(st);
    }
    setStatusLoading(false);

  };

  const columns = [
    {
      dataField: "id",
      text: props.t("ID"),
      formatter: (val) => {
        return <Button onClick={() => {
          showCompetitionStats(val);
        }}>
          {val.recordId}
        </Button>;
      }
    },
    {
      dataField: "dateFrom",
      text: props.t("Date From"),
      formatter: (val) => formatDate(val.dateFrom, "DD/MM/YYYY - hh:mm:ss")
    },
    {
      dataField: "dateTo",
      text: props.t("Date To"),
      formatter: (val) => formatDate(val.dateTo, "DD/MM/YYYY - hh:mm:ss")
    },
    {
      dataField: "name",
      text: props.t("Name"),
    },
    {
      dataField: "competitionType",
      text: props.t("Competition Type"),

    },
    {
      dataField: "clientType",
      text: props.t("Client Type")
    },
    {
      text: props.t("Account Type"),
      formatter: (val) => val.accountTypeId ? val.accountTypeId.title : "-"
    },
    {
      dataField: "totalWinnersNumber",
      text: props.t("Total Winners Number")
    },
    {
      dataField: "totalSubscribers",
      text: props.t("Total Subscribers"),
      formatter: (val) => val.subscribers.length
    },
    {
      dataField: "status",
      text: props.t("Status"),
    },
    {
      text: props.t("Activation"),
      formatter: (item, index) => (
        <div className="d-flex gap-3 justify-content-center">
          <React.Fragment>
            <Input
              checked={item.isActive}
              type="checkbox"
              onChange={() => { updateActivation(item, index) }}
              id={`${item._id}`}
              switch="none"
              disabled={statusLoading}
            />
            <Label className="me-1" htmlFor={`${item._id}`} data-on-label="" data-off-label=""></Label>
          </React.Fragment>
        </div>
      ),
    }
  ];

  return (<>
    <MetaTags>
      <title>
        {t("Competitions")}
      </title>
    </MetaTags>
    <div className="page-content">
      <div className="container-fluid">
        <h2 >{props.t("Competition List")}</h2>
        <Row>
          <Col className="col-12">
            <Card>
              <CardHeader className="d-flex flex-column gap-3">
                <div className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">{props.t("Competition List")} ({state.totalDocs})</CardTitle>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <CompetitionForm
                      t={t}
                      loadCompetitions={loadCompetitions}
                    />
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-hover table-clients"
                    >
                      <Thead className="text-center table-light">
                        <Tr>
                          {columns.map((column, index) =>
                            <Th data-priority={index} key={index}>
                              <span className="color-primary">{column.text}</span>
                            </Th>
                          )}
                        </Tr>
                      </Thead>
                      <Tbody className="text-center" style={{ fontSize: "13px" }}>
                        {loading && <TableLoader colSpan={4} />}
                        {!loading && state.docs.map((row, rowIndex) =>
                          <Tr key={rowIndex}>
                            {columns.map((column, index) =>
                              <Td key={`${rowIndex}-${index}`}
                                style={{
                                  overflow: column.dataField === "actions" && "visible",
                                  maxWidth: column.dataField === "actions" && "140px"
                                }}>
                                {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                              </Td>
                            )}
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                    <CustomPagination
                      {...state}
                      setSizePerPage={setSizePerPage}
                      sizePerPage={sizePerPage}
                      onChange={loadCompetitions}
                      docs={state.docs}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CompetitionStats
          loading={compLoading}
          t={props.t}
          {...selectedCompStats}
          loadCompetitions={loadCompetitions}
        >
        </CompetitionStats>
      </div>
    </div>
  </>);
}

export default withTranslation()(CompetitionList);
