import React, { useState } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { unlinkTradingAccountFromIb } from "apis/client";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorNotification, showSuccessNotification
} from "store/actions";
import { useTranslation } from "react-i18next";

export default function UnLinkAccount(props) {
  const { toggle, selectedAccount, show, fetchData } = props;
  const { linking } = useSelector((state) => state.clientReducer);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (obj) => {
    setLoading(true);
    unlinkTradingAccountFromIb(obj).then((res) => {
      if (res.isSuccess) {
        dispatch(showSuccessNotification("Successfully unlinked Account"));
        fetchData(1);
        toggle();
      } else {
        throw new Error(res.message);
      }
    }).catch(err => {
      console.log(err);
      dispatch(showErrorNotification("Error: " + err.message));
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Modal isOpen={show} size="lg" toggle={toggle} centered={true}>
      <AvForm
        className="needs-validation"
        autoComplete="off"
        onValidSubmit={() => {
          const obj = {
            login: selectedAccount.login,
            ibLogin: selectedAccount?.ibLink?.ibLogin,
            agreementId: selectedAccount?.ibLink?.agreementId?._id,
            platform: selectedAccount?.ibLink?.platform,
          };

          handleSubmit(obj);
        }}
      >
        <ModalHeader toggle={toggle} tag="h4">
          {t("UnLink Account")}
        </ModalHeader>
        <ModalBody >
          <Row>
            <AvField
              name="login"
              label={t("Account")}
              type="text"
              placeholder={t("Account")}
              required
              disabled
              value={selectedAccount?.login}
            />
          </Row>
          <Row >
            <div className="text-center">
              <span>{t("IB Login")} : </span>
              <span>{selectedAccount?.ibLink?.ibLogin}</span>
            </div>
            <div className="text-center">
              <span>{t("IB")} : </span>
              <span>{`${selectedAccount?.ibLink?.ibAccountId?.customerId?.firstName} ${selectedAccount?.ibLink?.ibAccountId?.customerId?.lastName}`}</span>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary"
            disabled={linking || loading}
          >
            {t("UnLink")}
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}
