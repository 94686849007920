import logo from "assets/images/brands/INZO-logo.png";

export const clientName = "INZO";
export const developedBy = "Exinitic";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002/";
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001/";
export const companyName =  "INZO";
export const sidebarLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;
