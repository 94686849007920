import { makeWinnerAPI } from "apis/competitions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { showErrorNotification } from "store/actions";


function WinnerButton(props) {
  const [winners, ] = useState(props.competitionDetails.winners);
  const [isWinner, setIsWinner] = useState(props.competitionDetails.winners.some(arr => arr.customerId === props.customer.customerId));

  useEffect(()=>{
    setIsWinner(props.competitionDetails.winners.some(arr => arr.customerId === props.customer.customerId));
  }, [winners]);

  return ( <>
    <Button onClick={async (event) => {
      props.onClick(event, {
        competitionDetails: props.competitionDetails,
        customer: props.customer 
      });
    }} disabled={props.competitionDetails.status !== "COMPLETED" || isWinner}>
      {isWinner ? props.t("Winner Assigned") : props.t("Assign Winner")}
    </Button>
  </> );
}

export default WinnerButton;