import * as axiosHelper from "./api_helper";
import qs from "qs";

export async function getCompetitions(params) {
  const data = await axiosHelper.get(`/competition?${qs.stringify(params)}`);
  if (data.isError) {
    throw new Error(data.isError);
  }
  return data.result;
}

export async function addCompetition(formData){
  const data = await axiosHelper.postFormData("/competition", formData);
}

export async function getCompetitionStats(id){
  const data = await axiosHelper.get(`/competition/${id}`);
  if (data.isError) {
    throw new Error(data.isError);
  }
  return data.result;
}

export async function makeWinnerAPI({ compId, winnerId, values }){
  const data = await axiosHelper.post("/competition/assign_winner", {
    compId,
    winnerId,
    reward: values
  });
  return data;
}

export async function updateIsActive(compId){
  const data = await axiosHelper.patch(`/competition/isActive/${compId}`);
  return data;
}